// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'
import { DocumentResource, DocumentsRoleGetRequest } from '@/store/types'


@Module({
  dynamic: true,
  name: 'documents',
  store,
  namespaced: true,
})

class Documents extends VuexModule {

  /**
   * Работа с документами
   */

  documents: DocumentResource[] = []

  @Action({ rawError: true })
  async getDocuments (role: any) {
    const { data } = await DocumentsRoleGetRequest(role)

    this.setDocuments(data)
  }

  @Mutation
  setDocuments (payload: any) {
    Vue.set(this, 'documents', payload)
  }

}

const DocumentsModule = getModule(Documents)

export default DocumentsModule
