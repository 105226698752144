























import { Component, Prop, Mixins } from 'vue-property-decorator'

import Card from '@/components/cards/Card.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    Card,
  },
})


export default class ContactCard extends Mixins(NotifyMixin){
  @Prop({ required: true })
  private document!: any

}
