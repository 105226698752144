


























// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import SmallDocumentCard from '@/components/cards/SmallDocumentCard.vue'
import BigDocumentCard from '@/components/cards/BigDocumentCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

//STORE
import SystemMixin from '@/mixins/SystemMixin'
import DocumentsModule from '@/store/modules/documents'
import { DocumentResource } from '@/store/types'

@Component({
  components: {
    DefaultLayout,
    SmallDocumentCard,
    BigDocumentCard,
    CaptionCard,
  },
})
export default class DocumentsLayout extends Mixins(NotifyMixin, SystemMixin) {
  private staticLinks: DocumentResource[] = [
    {
      file: {
        filename: 'Политика конфиденциальности',
        format: '',
        id: -1,
        size: 0,
        type:'link',
        url:'/storage/privacy-policy',
      },
      type:'default',
    },
    {
      file: {
        filename: 'Согласие на обработку персональных данных',
        format:'',
        id: -2,
        size: 0,
        type:'link',
        url:'/storage/terms-of-agreement',
      },
      type:'default',
    },
  ]

  private get documents () {
    return [
      ...DocumentsModule.documents.filter(item => item.type === 'default'),
      ...this.staticLinks,
    ]
  }

  private get individualDocuments () {
    return DocumentsModule.documents.filter(item => item.type === 'individuality')
  }

  private goToDocument (link: string){
    window.open(link, '_blank')
  }

  private created () {
    DocumentsModule.getDocuments(this.role)
  }
}
